import React from 'react'


const HowUse = () => {
  return (
    <div className="top_header_pg pg_bg">     
    <div className="page_spacing pg_min_height">    
        <div className="pg_msg">
          No video available at this time
        </div>        
    </div>
  </div>
  )
}

export default HowUse
